import cn from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import s from './ReferralInlet.module.css'

interface ReferralInletMetafields {
  inletLabel?: string
  referralUrl?: string
  closeIcon?: string
  referralIcon: string
  handles?: string[]
  pages_handles?: string[]
  collections_handles?: string[]
  blogs_handles?: string[]
}

interface ReferralInletProps {
  className?: string
  children?: any
  referralInletMetafields: ReferralInletMetafields
  path: string
  pathname?: string
}

const ReferralInlet: FC<ReferralInletProps> = ({
  referralInletMetafields,
  className,
  path,
  pathname,
}) => {
  const [isExpand, setIsExpand] = useState(true)

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsExpand(false)
      clearTimeout(timer)
    }, 3000)
  }, [])

  const showReferralInlet = useMemo(() => {
    let result = false
    if (
      referralInletMetafields.collections_handles &&
      path?.includes('collections')
    ) {
      for (const collection_handle of referralInletMetafields.collections_handles) {
        const paths = path.toLowerCase().split('/')
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === collection_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!collection_handle && temp
        if (result) break
      }
    }
    if (referralInletMetafields.blogs_handles && path?.includes('blogs')) {
      for (const blog_handle of referralInletMetafields.blogs_handles) {
        const paths = path.toLowerCase().split('/')
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === blog_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!blog_handle && temp
        if (result) break
      }
    }
    if (referralInletMetafields.pages_handles && pathname === '/[...pages]') {
      for (const pages_handle of referralInletMetafields.pages_handles) {
        const paths = path.toLowerCase().split('/')
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === pages_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!pages_handle && temp
        if (result) break
      }
    }
    if (referralInletMetafields.handles && path?.includes('products')) {
      for (const handle of referralInletMetafields.handles) {
        const paths = path.toLowerCase().split('/')
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!handle && temp
        if (result) break
      }
    }
    return result
  }, [referralInletMetafields, path, pathname])
  const rootClassName = cn(className, 'customer_service_chat', s.referralInlet)

  const handleReferralInletClick = useCallback(() => {
    window.open(referralInletMetafields.referralUrl)
  }, [referralInletMetafields])

  const handleMouseEnter = useCallback(() => {
    setIsExpand(true)
  }, [])

  if (!showReferralInlet) return null

  return (
    <div
      className={cn(rootClassName, {
        [s.referralInlet_expand]: isExpand,
      })}
      // start_ai_generated
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsExpand(false)}
      // end_ai_generated
    >
      <a className={s.referralInlet_box} onClick={handleReferralInletClick}>
        {isExpand ? (
          <span className={s.referralInlet_label}>
            {referralInletMetafields?.inletLabel}
          </span>
        ) : (
          <img
            src={referralInletMetafields?.referralIcon}
            alt="icon_referral"
            width={16}
            height={16}
          />
        )}
      </a>
    </div>
  )
}

export default ReferralInlet
